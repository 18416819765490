// document.addEventListener("DOMContentLoaded", function () {

function initializeSlider(selector, prevArrow, nextArrow, rtl = false) {
  const slider = $(selector);

  if (slider.length === 0) {
    return; // Slider element not found, skip initialization
  }

  const sliderOptions = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrow: true,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    rtl: rtl,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          //dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrow: true,
          //fade: true,
        },
      },
    ],
  };

  slider.slick(sliderOptions);
}

const prevArrow = $(".movePrev");
const nextArrow = $(".moveNext");
const prevArrowMt = $(".movePrev-mt");
const nextArrowMt = $(".moveNext-mt");

document.addEventListener("DOMContentLoaded", function () {
  initializeSlider("body.ltr #directors-slider", prevArrow, nextArrow);
  initializeSlider("body.rtl #directors-slider", nextArrow, prevArrow, true);
  initializeSlider("body.ltr #management-slider", prevArrowMt, nextArrowMt);
  initializeSlider(
    "body.rtl #management-slider",
    nextArrowMt,
    prevArrowMt,
    true,
  );
});
